<template>
  <div style="display: flex; text-align: center; justify-content: center">
    К сожалению вы не можете просматривать страницы для пользователей с ролью {{requiredRole}}.
    <br>
    Требуется выйти и зайти с указанной ролью
  </div>
</template>
<script>
  export default {
    name: 'wrongRole',
    computed: {
      requiredRole() {
        return 'expert'
      }
    }
  }
</script>